import React from "react"
import ToastServive from 'react-material-toast'


const defaultState = {
  items: [],
}

const CartContext = React.createContext(defaultState)

class CartProvider extends React.Component {
  state = {
    items: [],
    toast: null
  }

  setItems = (items) => {
    localStorage.setItem("cartItems", JSON.stringify(items))
    this.setState({ items })
  }

  addItem = (item) => {
    const items = [...this.state.items, item]
    localStorage.setItem("cartItems", JSON.stringify(items))
    this.setState({ items })

    if (this.state.toast)
      this.state.toast.success(`Item added to your cart!`)
  }

  removeItem = (index) => {
    console.log("INDEX", index)
    const items = [...this.state.items.slice(0, index), ...this.state.items.slice(index+1)]
    localStorage.setItem("cartItems", JSON.stringify(items))
    this.setState({ items })
  }
  
  componentDidMount() {
    const toast = ToastServive.new({
      place:'topRight',
      duration:3,
      maxCount:8
    })
    console.log("TOAST SERVICE", toast)
    this.setState({ toast })
    
    const items = JSON.parse(localStorage.getItem("cartItems"))
    if (items) {
      this.setState({ items })
    } 
  }
  render() {
    const { children } = this.props
    return (
      <CartContext.Provider
        value={{
            items: this.state.items,
            setItems: this.setItems,
            addItem: this.addItem,
            removeItem: this.removeItem
        }}
      >
        {children}
      </CartContext.Provider>
    )
  }
}

export default CartContext

export { CartProvider }