exports.linkResolver = function linkResolver(doc) {

  console.log("DOC TYPE", doc)

  if (doc.type === 'homepage') {
    return '/';
  }
  if (doc.type === 'products') {
    return '/products';
  }
  if (doc.type === 'product') {
    return '/products/' + doc.uid;
  }
  return '/';
}