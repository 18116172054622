import React from "react"
import { CartProvider } from "./src/context/CartConext"
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

 
registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => (
  <CartProvider>{element}</CartProvider>
)